<template>
  <form-slot-layout title="사업자 등록 번호">
    <validation-provider
      #default="{ errors }"
      name="사업자 등록 번호"
      rules="tax|required"
    >
      <cleave
        id="tax-number"
        v-model="showing"
        class="form-control"
        :raw="false"
        :options="delimiter"
        placeholder="000-00-00000"
        :disabled="readOnly"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </form-slot-layout>
</template>

<script>
import Cleave from 'vue-cleave-component'
import { computed, inject } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    Cleave,
    ValidationProvider,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { taxNumber } = inject('currentAccount')
    const showing = computed({
      get() {
        return taxNumber.value
      },
      set(value) {
        const re = RegExp('^[0-9]{1,10}$')
        const number = value.split('-').join('')
        // eslint-disable-next-line no-restricted-globals
        if (re.test(number)) {
          taxNumber.value = number
        }
      },
    })

    return {
      showing,
      delimiter: {
        delimiter: '-',
        blocks: [3, 2, 5],
        numericOnly: true,
      },
      required,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  font-size: medium;
  height: 45px;
}
</style>
