/* eslint-disable object-curly-newline */
import { ref, reactive, toRefs, provide, unref, readonly, computed } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useAccount() {
  const { t } = useI18nUtils()
  const refForm = ref(null)
  const refInvite = ref(null)
  const refChannel = ref(null)
  const cachedAccount = ref(null)
  const currentAccount = toRefs(reactive({
    id: null,
    name: null,
    taxNumber: null,
    trackerCode: null,
    status: '',
    apiKeys: {
      facebook: null,
      google: null,
    },
  }))

  const apiKeys = toRefs(reactive({ facebook: null, google: null }))
  const siteUrls = ref([])
  const assignedMembers = ref([])
  const newMembers = ref([{
    email: '',
    permission: {
      text: '읽기 전용',
      value: 'READ',
    },
  }])

  const setCurrentAccount = account => {
    currentAccount.id.value = account.id
    currentAccount.name.value = account.name
    currentAccount.taxNumber.value = account.taxNumber
    currentAccount.trackerCode.value = account.trackerCode
    currentAccount.status.value = account.status
    currentAccount.apiKeys.value = {
      facebook: account.apiKeys.facebook,
      google: account.apiKeys.google,
    }
    cachedAccount.value = JSON.stringify({
      id: account.id,
      name: account.name,
      taxNumber: account.taxNumber,
      siteUrls: account.siteUrls,
      trackerCode: account.trackerCode,
      apiKeys: {
        facebook: account.apiKeys.facebook,
        google: account.apiKeys.google,
      },
      status: account.status,
    })

    apiKeys.facebook.value = account.apiKeys.facebook
    apiKeys.google.value = account.apiKeys.google

    siteUrls.value = []
    if (Array.isArray(account.siteUrls)) {
      siteUrls.value.push(...account.siteUrls.map(url => ref(url)))
    }

    assignedMembers.value = []
    assignedMembers.value.push(...account.assignedMembers.map(member => reactive(member)))
  }

  const makeAccountData = () => {
    const data = {
      id: currentAccount.id.value,
      name: currentAccount.name.value,
      taxNumber: currentAccount.taxNumber.value,
      trackerCode: currentAccount.trackerCode.value,
      siteUrls: siteUrls.value.map(url => unref(url)),
      apiKeys: {
        facebook: apiKeys.facebook.value,
        google: apiKeys.google.value,
      },
      status: currentAccount.status,
    }
    return data
  }

  const permissionOptions = [
    {
      text: t('MANAGE'),
      value: 'MANAGE',
    },
    {
      text: t('WRITE'),
      value: 'WRITE',
    },
    {
      text: t('READ'),
      value: 'READ',
    },
    {
      text: t('EJECT'),
      value: 'EJECT',
    },
  ]

  const permissionErrorText = ref('')
  const permissionState = computed(() => {
    const result = assignedMembers.value.filter(m => m.permission === 'MANAGE').length > 0
    if (result) {
      permissionErrorText.value = ''
    } else {
      permissionErrorText.value = '최소 1명의 관리자를 선택해주세요.'
    }
    return result
  })

  const validate = () => refForm.value.validate()
    .then(result => result && siteUrls.value.length !== 0
      && siteUrls.value.filter(u => u.value).length === siteUrls.value.length)

  const canUpdate = computed(() => cachedAccount.value !== JSON.stringify((makeAccountData())))

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  provide('currentAccount', currentAccount)
  provide('siteUrls', siteUrls)
  provide('apiKeys', apiKeys)
  provide('assignedMembers', assignedMembers)
  provide('permissionOptions', readonly(permissionOptions))
  provide('permissionErrorText', readonly(permissionErrorText))
  provide('newMembers', newMembers)

  return {
    refForm,
    refInvite,
    refChannel,
    canUpdate,
    apiKeys,
    permissionState,
    newMembers,
    validate,
    setCurrentAccount,
    currentAccount,
    assignedMembers,
    makeAccountData,
  }
}
