<template>
  <div class="account-form-wrapper">
    <h3>{{ title }}</h3>
    <div class="divider"/>
    <slot></slot>
    <b-row align-h="center">
      <b-col cols="4">
        <div class="submit-btn">
          <slot name="submit-button"></slot>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.account-form-wrapper {
  padding-right: 50px;
  padding-left: 50px;

  h3 {
    margin-top: 50px;
  }
  .divider {
    width: 100%;
    height: 2px;
    overflow: hidden;
    background-color: var(--secondary);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .submit-btn {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
</style>
