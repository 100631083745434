<template>
  <form-slot-layout title="URL 추가하기">
    <template #title-suffix>
      <b-button
        v-if="!readOnly"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        pill
        size="sm"
        variant="primary"
        @click="repeateAgain"
      >
        <b-icon
          icon="plus"
        />
        추가
      </b-button>
    </template>
    <b-form
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >

      <!-- Row Loop -->
      <div
        v-for="(item, index) in siteUrls"
        :id="index"
        :key="index"
        ref="row"
        class="repeater-row"
      >
        <!-- Url input -->
        <validation-provider
          #default="{ errors }"
          name="Url 주소"
          rules="required|url"
        >
          <div class="repeater-info">
            <b-form-input
              id="item-name"
              v-model="siteUrls[index].value"
              size="lg"
              type="url"
              placeholder="https://my-url.com"
              :disabled="readOnly"
              class="input-form"
            />

            <!-- Remove Button -->
            <b-button
              v-if="!readOnly"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="delete-btn"
              pill
              size="sm"
              variant="danger"
              :disabled="siteUrls.length === 1"
              @click="removeItem(index)"
            >
              <b-icon
                icon="x"
              />
              삭제
            </b-button>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

    </b-form>
  </form-slot-layout>

</template>

<script>
import {
  BForm, BFormInput, BButton,
  BIcon,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { url } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { inject, ref } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BForm,
    BButton,
    BFormInput,
    BIcon,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.siteUrls.push(ref(''))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.siteUrls.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form?.scrollHeight)
      })
    },
  },
  setup() {
    const siteUrls = inject('siteUrls')

    return {
      siteUrls,
      url,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  font-size: large;
  overflow: visible;
  transition: .35s height;
  min-height: 60px;

  .repeater-row {
    margin-bottom: 20px;

    .repeater-info {
      display: flex;
      align-items: center;

      .input-form {
        flex-grow: 1;
      }

      .delete-btn {
        margin-left: 20px;
        white-space: nowrap;
      }
    }
  }
}
</style>
