import { ref } from '@vue/composition-api'
import store from '@/store'

export default function useAccountTask() {
  const loading = ref(false)
  const error = ref(null)

  const fetchChannelAccount = async id => {
    loading.value = true
    return store.dispatch('account/fetchChannelAccount', id)
      .then(response => {
        const account = response.data.payload
        return account
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchAdAccount = async id => {
    loading.value = true
    return store.dispatch('account/fetchAdAccount', id)
      .then(response => {
        const account = response.data.payload
        return account
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const createChannelAccount = accountName => {
    loading.value = true
    return store.dispatch('account/createChannelAccount', accountName)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.payload
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const createAdAccount = accountName => {
    loading.value = true
    return store.dispatch('account/createAdAccount', accountName)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.payload
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateChannelAccount = account => {
    loading.value = true
    return store.dispatch('account/updateChannelAccount', account)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateAdAccount = account => {
    loading.value = true
    return store.dispatch('account/updateAdAccount', account)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const editAdAccountPermission = ({ accountId, memberId, permission }) => {
    loading.value = true
    if (permission === 'EJECT') {
      return store.dispatch('account/deleteAdAccountPermission', { accountId, memberId })
        .then(response => {
          if (response.data.status === 2000) {
            return response.data.payload
          }
          throw response.data.message
        })
        .catch(e => {
          error.value = e
        })
        .finally(() => {
          loading.value = false
        })
    }
    return store.dispatch('account/editAdAccountPermission', { accountId, memberId, permission })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const editChannelAccountPermission = ({ accountId, memberId, permission }) => {
    loading.value = true
    if (permission === 'EJECT') {
      return store.dispatch('account/deleteChannelAccountPermission', { accountId, memberId })
        .then(response => {
          if (response.data.status === 2000) {
            return response.data.payload
          }
          throw response.data.message
        })
        .catch(e => {
          error.value = e
        })
        .finally(() => {
          loading.value = false
        })
    }
    return store.dispatch('account/editChannelAccountPermission', { accountId, memberId, permission })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,
    error,
    fetchChannelAccount,
    fetchAdAccount,
    createChannelAccount,
    createAdAccount,
    updateChannelAccount,
    updateAdAccount,
    editChannelAccountPermission,
    editAdAccountPermission,
  }
}
